.userMenu {
  position: absolute;
  top: 100%;
  right: 0;
  width: 100%;
  max-width: 36ch;
  background: black;
  box-sizing: border-box;
}

.userMenu * {
  display: block;
  width: 100%;
  box-sizing: border-box;
}

.errorMsg {
  padding: calc(var(--gap-half) / 2);
}

.wrapper {
  position: fixed;
  width: 100%;
}

.contents {
  height: var(--bar-height);

  display: grid;
  grid-auto-flow: column;
  align-items: center;

  position: relative;
}

.spacer {
  height: var(--bar-height);
}

.logo {
  color: white;
  font-size: 1.5rem;
}

.navBtn {
  background: none;
  padding: 0;
}
.navBtn:hover {
  background: none;
  cursor: pointer;
}
